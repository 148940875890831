import React from 'react';
import PlayIconLight from '../../../util/icons/components/PlayIconLight';
import Logo from '../../../header/components/Logo';
import PropTypes from 'prop-types';
import GBIGradeShieldAndConceptModal
  from '../../../util/logos/GBIGradeSheildAndConceptModal';

const CowListImage = ({ cow, onClick }) => {
  let imgSrc = '';
  let playIcon = null;
  if (cow.images && cow.images.length > 0) {
    if (window.devicePixelRatio > 1 && cow.images[0].thumb660) {
      imgSrc = cow.images[0].thumb660;
    } else if (cow.images[0].thumb330) {
      imgSrc = cow.images[0].thumb330;
    }
  } else if (cow.video && cow.video.thumb) {
    imgSrc = cow.video.thumb;
  } else if (cow.orgImage) {
    if (window.devicePixelRatio > 1 && cow.orgImage.thumb660) {
      imgSrc = cow.orgImage.thumb660;
    } else if (cow.orgImage.thumb330) {
      imgSrc = cow.orgImage.thumb330;
    }
  }
  if (cow.video || cow.orgVideo) {
    playIcon = <PlayIconLight />;
  }
  const imageStyles = imgSrc ? { backgroundImage: `url('${imgSrc}')` } : null;
  const className = imgSrc ? 'image' : 'image faded';
  const logo = imgSrc ? null : <Logo noLink={ true } />;
  const gradeIcon = (cow && cow.grade && cow.grade !== 'Ungraded') ? (
    <GBIGradeShieldAndConceptModal
      score={ cow.beefQualityIndex } />
  ) : null;
  return (
    <div onClick={ onClick }
      className={ className }
      style={imageStyles}>
      { logo }
      { playIcon }
      { gradeIcon }
    </div>
  );
};

CowListImage.propTypes = {
  cow: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default CowListImage;
