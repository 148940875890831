import React, { Component } from 'react';
import { connect } from 'react-redux';
import './cow-filters.scss';
import './filters-sliders.scss';
import { resetAllFilters, setSidebarVisibility } from '../actions';
import PropTypes from 'prop-types';
import AttributeFilter from './AttributeFilter';
import BreedFilter from './BreedFilter';
import CloseIcon from '../../util/icons/components/CloseIcon';
import { getCowType, isSidebarVisible } from '../reducer';
import RefreshIcon from '../../util/icons/components/RefreshIcon';
import SortFilter from './SortFilter';
import UserInteractionFilter from './UserInteractionFilter';
import analytics from '../../util/analytics';
import ScoreFilter from './ScoreFilter';
import PriceFilter from './PriceFilter';
import AgeFilter from './AgeFilter';
import SearchFilter from './SearchFilter';

const mapStateToProps = state => ({
  visible: isSidebarVisible(state),
  cowType: getCowType(state)
});

const mapDispatchToProps = dispatch => ({
  resetFilters: () => dispatch(resetAllFilters()),
  setVisibility: visible => dispatch(setSidebarVisibility(visible))
});

class CowFilters extends Component {
  constructor (props) {
    super(props);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }
  handleCloseClicked () {
    analytics('send', 'event', 'searchHeader', 'hideSidebar');
    this.props.setVisibility(false);
  }
  render () {
    const className = this.props.visible ? 'visible' : 'hidden';
    const priceFilter = this.props.cowType !== 'FarmSale' ?
      <PriceFilter /> : null;
    return (
      <article id='bb-cow-filters' className={ className }>
        <CloseIcon onClick={this.handleCloseClicked }
          testId="close-filters-button"/>
        <SortFilter />
        <SearchFilter />
        <div id='filters-sliders' className='filter-block'>
          <h3>Filters</h3>
          <ScoreFilter />
          { priceFilter }
          <AgeFilter />
        </div>
        <AttributeFilter />
        <BreedFilter />
        <UserInteractionFilter />
        <div onClick={ this.props.resetFilters }
          className='reset-section filter-block'>
          <RefreshIcon />
          Reset your search
        </div>
      </article>
    );
  }
}

CowFilters.propTypes = {
  resetFilters: PropTypes.func,
  visible: PropTypes.bool,
  cowType: PropTypes.string,
  setVisibility: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CowFilters);
