import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '../../../util/buttons/components/Button';
import ViewButton from '../../../util/buttons/components/ViewButton';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cost, costPerKilo } from '../../../util/number';
import './cow-list-item.scss';
import HeartIcon from '../../../util/icons/components/HeartIcon';
import { setVideoPlaying } from '../../actions';
import RegisterInterestModal from
  '../../../user/components/RegisterInterestModal';
import BeefBookQualityIndex from './BeefBookQualityIndex';
import { markFavourite } from '../../../user/actions';
import analytics from '../../../util/analytics';
import { monthsStringFromDate, timeFromNow } from '../../../util/datesAndAges';
import CowListImage from './CowListImage';
import { captureException } from '../../../util/logger';

const mapDispatchToProps = dispatch => ({
  setVideoPlaying: playing => dispatch(setVideoPlaying(playing)),
  favourite: (cow, isFavourite) => dispatch(markFavourite(cow, isFavourite))
});

class CowListItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      registering: false,
      mountTime: null
    };
    this.cowElementRef = React.createRef();
    this.registerInterest = this.registerInterest.bind(this);
    this.hideRegisterInterest = this.hideRegisterInterest.bind(this);
    this.handleImageClicked = this.handleImageClicked.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
  }
  componentDidMount () {
    this.setState({ mountTime: new Date() });
  }
  registerInterest () {
    this.setState({ registering: true });
  }
  hideRegisterInterest () {
    this.setState({ registering: false });
    this.cowElementRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  renderBreedPlanScores () {
    let tri = null;
    let sri = null;
    if (this.props.cow.EBV && this.props.cow.EBV.TIscore) {
      tri = (
        <div className="ebv">
          <div key='TIscore-score' className='score'>
            {this.props.cow.EBV.TIscore}
          </div>
          <div key='TIscore-title' className='title'>TI</div>
        </div>
      );
    }
    if (this.props.cow.EBV && this.props.cow.EBV.SRIscore) {
      sri = (
        <div className="ebv">
          <div key='SRIscore-score' className='score'>
            {this.props.cow.EBV.SRIscore}
          </div>
          <div key='SRIscore-title' className='title'>SRI</div>
        </div>
      );
    }
    let ratingClass = 'grade-E';
    try {
      if (parseInt(this.props.cow.EBV.rank) <= 10) {
        ratingClass = 'grade-A';
      } else if (parseInt(this.props.cow.EBV.rank) <= 20) {
        ratingClass = 'grade-B';
      } else if (parseInt(this.props.cow.EBV.rank) <= 40) {
        ratingClass = 'grade-C';
      } else if (parseInt(this.props.cow.EBV.rank) <= 60) {
        ratingClass = 'grade-D';
      }
    } catch (error) {
      captureException(error);
    }
    const rank = this.props.cow.EBV ? this.props.cow.EBV.rank : '?';
    return (
      <div className="top">
        <h5 className='header-title'>
          <span className="long-title">Estimated Breed Value (EBV)</span>
          <span className="short-title">EBV</span>
          <Link to="/about/breedplan">
            <div data-cy={`desktop-ebv-icon-${this.props.cow.id}`}
              className="desktop bb-info-icon">i</div>
          </Link>
        </h5>
        { tri }
        { sri }
        <Link to="/about/breedplan">
          <div data-cy={`mobile-ebv-icon-${this.props.cow.id}`}
            className="mobile bb-info-icon">i</div>
        </Link>
        <h6 className={'summary ' + ratingClass }>
          { 'top ' + rank }
        </h6>
      </div>
    );
  }
  getCowURL () {
    return window.location.pathname + '/' + this.props.cow.id + '/' +
      encodeURIComponent(this.props.cow.name).replace(/%20/g, '-');
  }
  handleImageClicked () {
    if (window.innerWidth > 700) {
      this.props.setVideoPlaying(true);
    }
  }
  renderRegisterInterestModal () {
    if (!this.state.registering) return null;
    return (
      <RegisterInterestModal
        cow={this.props.cow}
        close={this.hideRegisterInterest} />
    );
  }
  renderAgeAndAvailability () {
    let availability = null;
    if (this.props.cow.availabilityStatus === 'AVAILABLE') {
      let availStr = 'now';
      if (this.props.cow.availableDate &&
      new Date(this.props.cow.availableDate) > new Date()) {
        availStr = timeFromNow(this.props.cow.availableDate);
      }
      availability = (
        <Fragment>
          <span className="title">available:</span> { availStr }
        </Fragment>
      );
    } else if (this.props.cow.availabilityStatus === 'RESERVED') {
      availability = <span className="title reserved">reserved</span>;
    }
    const age = this.props.cow &&
      this.props.cow.type === 'FinishedCattle' &&
      this.props.cow.availabilityStatus !== 'SOLD' ? (
        <div className="age">
          { monthsStringFromDate(this.props.cow.dob)}
        </div>
      ) : null;
    return (
      <div className="age-and-availability">
        <div className="available">
          { availability }
        </div>
        { age }
      </div>
    );
  }
  toggleFavourite () {
    let state = 'off';
    if (this.props.cow.favourite) {
      state = 'on';
    }
    analytics('send', 'event', 'cowListItem', 'favourite', state);
    this.props.favourite(this.props.cow, !this.props.cow.favourite);
  }
  renderPrice () {
    if (this.props.cow.type === 'FinishedCattle') {
      const price = this.props.cow.pricePerKilo ?
        costPerKilo.format(this.props.cow.pricePerKilo / 100) : '£-';
      return (
        <div className='price'>
          <span className="smaller">
            { price }
          </span>
          <span className="per-kilo">/kg</span>
          <div className="price-label">
            <div className="guide-price">guide price</div>
            <div className="carcass-weight">carcass weight</div>
          </div>
        </div>
      );
    }
    const price = this.props.cow.price ?
      cost.format(this.props.cow.price / 100) : '£-';
    return (
      <div className='price'>
        <span>{ price }</span>
        <div className="price-label">
          <div className="guide-price">indicative price</div>
        </div>
      </div>
    );
  }
  renderRegisterInterestButton () {
    if (this.props.cow.availabilityStatus === 'SOLD') {
      return (
        <div className="sold-text">sold</div>
      );
    }
    if (this.props.cow.interestRegistered) {
      return (
        <div className="be-in-touch"
          data-cy="interest-registered-msg">
          { 'We\'ll be in touch' }
        </div>
      );
    }
    return (
      <Button
        className="register"
        testId={ `purchase-enquiry-button-${this.props.cow.id}`}
        onClick={ this.registerInterest }
        label='Purchase Inquiry' />
    );
  }
  renderPricingPopout () {
    let pageURL = 'breeding-cattle';
    if (this.props.cow.type === 'FinishedCattle') {
      pageURL = 'finished-cattle';
    }
    return (
      <Link to={ `/pricing-guidelines/${pageURL}` }>
        <div data-cy={`pricing-link-icon-${this.props.cow.id}`}
          className="bb-info-icon">i</div>
      </Link>
    );
  }
  renderEBVsOrIMF () {
    let content = null;
    if (this.props.cow && this.props.cow.type === 'FinishedCattle') {
      let ratingClass = 'grade-D';
      if (parseInt(this.props.cow.IMF) >= 6) {
        ratingClass = 'grade-A';
      } else if (parseInt(this.props.cow.IMF) >= 4) {
        ratingClass = 'grade-B';
      } else if (parseInt(this.props.cow.IMF) >= 2) {
        ratingClass = 'grade-C';
      }
      const IMF = this.props.cow.IMF || '?';
      content = (
        <div className="top marbling">
          <h5 className='header-title'>
            <span className="long-title">Marbling Scale</span>
            <span className="short-title">Marbling</span>
          </h5>
          <h6 className={'summary ' + ratingClass }>
            <span>{ IMF + '%' }</span>
            {/* <InfoModal
              contentTestId="marbling-popout"
              iconTestId={ `marbling-info-icon-${this.props.cow.id}`}>
              <BeefMarblingInfo />
            </InfoModal> */}
            <Link to="/about/marbling">
              <div data-cy={`marbling-info-icon-${this.props.cow.id}`}
                className="bb-info-icon">i</div>
            </Link>
          </h6>
        </div>
      );
    } else {
      content = this.renderBreedPlanScores();
    }
    const heartClass = this.props.cow.favourite ? 'mobile selected' : 'mobile';
    return (
      <div className='breedplan-marbling-section'>
        { content }
        { this.renderAgeAndAvailability() }
        <div className="base">
          <Link to={ this.getCowURL() } >
            { this.renderPrice() }
          </Link>
          { this.renderPricingPopout() }
          <Link className="view-link-wrapper" to={ this.getCowURL() } >
            <ViewButton className='view mobile' label='View' />
          </Link>
          <HeartIcon
            testId={ `favourite-icon-${this.props.cow.id}-mobile`}
            onClick={this.toggleFavourite}
            className={ heartClass } />
        </div>
      </div>
    );
  }
  render () {
    return (
      <li ref={this.cowElementRef} className='bb-cow-list-item'
        data-cy="cow-list-item">
        { this.renderRegisterInterestModal() }
        <Link to={ this.getCowURL() } className="image-wrapper">
          <CowListImage cow={ this.props.cow }
            onClick={ this.handleImageClicked } />
        </Link>
        <div className='details'>
          <h5 >
            <span className='breed'>{ this.props.cow.breed } </span>
            <span className='pipe'>|</span>
            <span className='location'>
              { this.props.cow.location }
            </span>
          </h5>
          <Link to={ this.getCowURL() } >
            <h3>{this.props.cow.name} </h3>
          </Link>
          <h6 className="tag">{ this.props.cow.tag }</h6>
          <BeefBookQualityIndex
            mountTime={this.state.mountTime }
            cow={this.props.cow} />
        </div>
        { this.renderEBVsOrIMF() }
        <div className='button-section'>
          <HeartIcon
            testId={ `favourite-icon-${this.props.cow.id}-desktop`}
            onClick={this.toggleFavourite}
            className={this.props.cow.favourite ? 'selected' : null} />
          <div className="buttons">
            <Link to={ this.getCowURL() } >
              <ViewButton className='view' label='View' />
            </Link>
            { this.renderRegisterInterestButton() }
          </div>
        </div>
      </li>
    );
  }
}

CowListItem.propTypes = {
  cow: PropTypes.object.isRequired,
  setVideoPlaying: PropTypes.func.isRequired,
  favourite: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(CowListItem);
