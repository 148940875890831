import React from 'react';
import SmallDonutChart from '../../../util/charts/components/SmallDonutChart';
import PropTypes from 'prop-types';
import './beefbook-quality-index.scss';
import GoodBeefSheild from '../../../util/icons/components/GoodBeefShield';
import { Link } from 'react-router-dom';

const BeefBookQualityIndex = ({ cow, mountTime }) => {
  const runTime = cow.beefQualityIndex * 20;
  const update = () => {
    const scoreElement = document.getElementById('score_' + cow.id);
    if (!scoreElement) {
      return window.requestAnimationFrame(update);
    }
    const now = new Date();
    const timePassed = now - mountTime;
    const rawScore =
      Math.pow((timePassed / runTime), 0.15) * cow.beefQualityIndex;
    const currentScore = Math.round(rawScore);
    if (currentScore < cow.beefQualityIndex) {
      scoreElement.innerHTML = currentScore;
      window.requestAnimationFrame(update);
    } else {
      scoreElement.innerHTML = cow.beefQualityIndex;
    }
  };
  update();
  return (
    <div className='beefbook-quality-index'>
      <div className="line"></div>
      <SmallDonutChart
        score={cow.production.qualityIndex}
        hover={true}
        type='production' />
      <SmallDonutChart
        score={cow.eating.qualityIndex}
        hover={true}
        type='eating' />
      <SmallDonutChart
        score={cow.environment.qualityIndex}
        hover={true}
        type='environment' />
      <div className="beefbook-score">
        <GoodBeefSheild className="small" id={ cow.id } />
        <h6 className="label">
          <span className="bb-index-good">Good</span>
          <span className="bb-index-beef">Beef</span>
          <br/><span className="bb-index-index">Index</span>
        </h6>
      </div>
      <Link to="/about/good-beef-index">
        <div data-cy={`gbi-score-info-${cow.id}`}
          className="bb-info-icon">i</div>
      </Link>
      {/* <InfoModal contentClass="gbi-info-popout"
        contentTestId="gbi-score-modal"
        iconTestId={ `gbi-score-info-${cow.id}` }>
        <GoodBeefInfo score={ cow.beefQualityIndex }/>
      </InfoModal> */}
    </div>
  );
};

BeefBookQualityIndex.propTypes = {
  cow: PropTypes.object.isRequired,
  mountTime: PropTypes.instanceOf(Date)
};

export default BeefBookQualityIndex;
